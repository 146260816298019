

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.my_button{
	cursor: pointer;
	padding: 16px 26px; 
	background: rgba(48, 48, 60, 0.6); 
	float: left; 
	-moz-user-select:none;
}
.my_button:hover{
	box-shadow: 0 2px 8px #fff;
	background: rgba(48, 48, 60, 0.8); 
}
